<template>
  <div>
    <div class="bg">
      <div class="h1">郁金香VIP文档，超多文档任你选</div>
      <div class="header_search">
        <div style="display: flex; align-items: center">
          <img src="@/assets/image/icon2.png" alt="" />
          <el-input v-model="keyword" placeholder="请输入关键字"></el-input>
        </div>
        <div style="display: flex; align-items: center">
          <div class="search_line"></div>
          <div class="search_text" @click="search()">搜索文档</div>
        </div>
      </div>
      <!-- 文档列表 -->
      <div class="contain2">
        <div class="screen_contain">
          <div
            class="screen_item"
            :class="{ screen_item_active: current == item.key }"
            v-for="(item, index) in screen"
            :key="index"
            @click="changeScreen(item)"
          >
            <div>{{ item.name }}</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="contain2_line"></div>
        <div class="list_item" v-for="(item, index) in list" :key="index" @click="goDocDetail(item)">
          <div class="item_left">
            <img :src="`${$imgURL + '/view/cover/' + item.attachment.hash}`" alt="" />
          </div>
          <div class="item_right">
            <div class="item_right_title">
              {{ item.title }}
            </div>
            <div class="item_right_info">
              {{ item.description }}
            </div>
            <div class="item_right_tiem">
              <div>
                {{ item.created_at.split("T")[0] }}&nbsp;{{
                  item.created_at.split("T")[1].split("Z")[0]
                }}
              </div>
              <div><img src="@/assets/image/icon22.png" alt="" /></div>
            </div>
            <div class="item_right_line"></div>
          </div>
        </div>
        <div class="pagination_contain">
          <div>共{{ total || 0 }}项数据</div>
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="page"
            :page-size="size"
            :total="total"
            @current-change="getDocumentList"
          >
          </el-pagination>
        </div>
        <div style="height: 50px"></div>
      </div>
    </div>
    <div style="width: 100%; height: 22px"></div>
  </div>
</template>
  
  <script>
import { get } from "/utils/request";
export default {
  data() {
    return {
      token: "",
      keyword: "",
      current: "recommend_at desc",
      screen: [
        { id: 1, name: "推荐文章", key: "recommend_at desc" },
        { id: 2, name: "最新上传", key: "id desc" },
      ],
      page: 1,
      size: 10,
      total: "",
      list: [], //文档列表
    };
  },
  created() {
    this.getDocumentList();
  },
  watch: {},
  methods: {
    search() {
      this.getDocumentList();
    },
    changeScreen(item) {
      this.current = item.key;
      this.page = 1;
      this.list = [];
      this.getDocumentList();
    },
    getDocumentList(val) {
      this.page = val;
      get("/document/list", {
        order: this.current, //推荐 最新排序
        status: 2,
        page: this.page,
        size: 10,
        // category_id: this.id, //分类id
        // ext: this.currentFormat, //文件类型
        fee_type: "vip", //费用
        wd: this.keyword, //搜索关键字
      }).then((res) => {
        console.log("res", res);
        this.total = res.total;
        this.list = res.document;
      });
    },
    goDocDetail(item) {
      window.open( this.$router.resolve({ path: `/docDetail?id=${item.id}` }).href, '_blank');

      // this.$router.push({ name: "docDetail", query: { id: item.id } });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.bg {
  width: 100%;
  //   height: 620px;
  background: url("../../assets/image/bg.png");
  background-size: 100% 622px;
  background-repeat: no-repeat;
  padding: 50px 0 0;
  box-sizing: border-box;
  .header_search {
    width: 696px;
    height: 52px;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 55px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    ::v-deep .el-input__inner {
      border: none !important;
      padding-left: 0;
    }
    .search_line {
      width: 1px;
      height: 25px;
      background: #efefef;
    }
    .search_text {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23dc5a;
      margin-left: 16px;
      cursor: pointer;
    }
  }
  .h1 {
    width: 798px;
    font-size: 52px;
    font-family: zihun143-zhengkuchaojihei, zihun143-zhengkuchaojihei-Regular;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 2.34px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
    margin: 0 auto 20px;
  }
}
.contain2 {
  width: 1400px;
  // height: 2357px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px 20px 0;
  box-sizing: border-box;
  .screen_contain {
    display: flex;
    align-items: center;
    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      position: relative;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.9px;
        margin-bottom: 13px;
      }
      .line {
        width: 75px;
        height: 7px;
        background: transparent;
        position: absolute;
        bottom: 14px;
      }
    }
    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      position: relative;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
        letter-spacing: 0.9px;
        margin-bottom: 13px;
      }
      .line {
        width: 75px;
        height: 7px;
        background: linear-gradient(180deg, #23c48c, #33da61);
        opacity: 0.8;
        position: absolute;
        bottom: 12px;
      }
    }
  }
  .contain2_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  .list_item {
    display: flex;
    cursor: pointer;
    .item_left,
    img {
      // width: 414px;
      // height: 175px;
      width: 414px;
        height: 360px;
      background: rgba(0, 0, 0, 0);
      border-radius: 6px;
      margin-right: 20px;
    }
    .item_right {
      .item_right_title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
        width: 926px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 26px;
      }
      .item_right_info {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.63px;
        margin: 20px 0;
        width: 926px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .item_right_tiem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.63px;
        }
        div:nth-child(2) {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .item_right_line {
        width: 926px;
        height: 1px;
        background: #f4f4f4;
        margin: 45px 0 15px 0;
      }
    }
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>